.comentario {
  display: flex;
  background: #eaeaea;
  color: #434343;
  padding: 0.8rem;
  border-radius: 8px;
  width: 60vw;
  margin-top: 5px;
  border-top-left-radius: 0px;
}
